import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Warmup:`}</p>
    <p>{`3×1:00 Plank`}</p>
    <p>{`3×20 Superman Extensions`}</p>
    <p>{`then,`}</p>
    <p>{`10:00 EMOM of:`}</p>
    <p>{`1-Clean Grip Deadlift to just below knees`}</p>
    <p>{`:05 pause`}</p>
    <p>{`1-Clean Grip Deadlift from below knees to full extension`}</p>
    <p>{`1-Clean Grip Deadlift`}</p>
    <p>{`*`}{`using 90-100% Clean 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`“Diane”`}</p>
    <p>{`21-15-9 reps each of:`}</p>
    <p>{`Deadlifts (225/155)`}</p>
    <p>{`HSPU’s`}</p>
    <p><em parentName="p">{`*`}{`compare to 7/27/19`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      